export type CoursetType = "grundkurs" | "weiterfuehrend" | "spezial";

export class CourseDataProvider {
    private fetchCourses?: Promise<IPrivateKursData[]>;

    public async getCourse(id: number) : Promise<IKursItemProps>
    {
        let response = await fetch("/api/courses/"+id);
        return this.convertData(await response.json());
    }

    private types = {
        "grundkurs": 1,
        "weiterfuehrend": 3,
        "spezial": 6
    }

    public async getCourseList(type: CoursetType): Promise<IKursItemProps[]>
    { 
        if(!this.fetchCourses)
        {
            this.fetchCourses = fetch("/api/courses").then(response => response.json());
        }

        // eslint-disable-next-line
        return this.convertDataList((await this.fetchCourses).filter(c => c.type == this.types[type]));
    }

    private convertDataList(data: IPrivateKursData[]): IKursItemProps[]{

       return data.map(item => this.convertData(item));
    }

    private convertData(data: IPrivateKursData) : IKursItemProps
    {
        return {
            ...data,
            startDate: new Date(data.startDate as unknown as string),
            endDate: new Date(data.endDate as unknown as string),
            pdf: data.description !== "" ? {to: "/static/media/"+data.description, text: "Kursbeschrieb PDF"} : undefined,
            anmeldung: {to: "/angebot/kurse/"+data.id+"/anmeldung-"+this.slugify(data.teaser)},
        }
    }

    private slugify(str: string){
        return str
        .normalize('NFKD')
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .trim()
        .replace(/[-\s]+/g, '-');
    }
};

export interface IKursLink {
    to: string;
    text?: string;
}

interface IKursData {
    id: number;
    title: string;
    teaser: string;
    text: string;
    date: string;
    startDate?: Date;
    endDate?: Date;
    location?: string;
    name?: string;
    order?: number; 
    planned: boolean;
    full: boolean;
    provideCertificate: boolean;
    provideConfirmation: boolean;
}

interface IPrivateKursData extends IKursData {
    description: string;
    type: number;
}
  
export interface IKursItemProps extends IKursData {    
    pdf?: IKursLink;
    anmeldung?: IKursLink;
    mobileImage?: string;
    tabletImage?: string;
    desktopImage?: string;
    linkText?: string;    
}