import React, { CSSProperties, Fragment } from 'react';
import Carousel, { RenderArrowProps } from 'react-elastic-carousel';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

import {CourseDataProvider, IKursItemProps} from './CourseDataProvider';
import {AnnouncementDataProvider, IAnnouncementBubbleProps} from './AnnouncementDataProvider';

import placeHolderSmall from './images/1_Cover Startseite/Mobile/210081_us_7009_720x370.jpg';
import placeHolderTablet from './images/1_Cover Startseite/Tablet/210081_us_7009_1140x300.jpg';
import placeHolderDesktop from './images/1_Cover Startseite/Desktop/210081_us_7009_1920x795.jpg';

import coverStart1Mobile from './images/1_Cover Startseite/Mobile/210081_us_7009_720x370.jpg';
import coverStart1Tablet from './images/1_Cover Startseite/Tablet/210081_us_7009_1140x300.jpg';
import coverStart1Desktop from './images/1_Cover Startseite/Desktop/210081_us_7009_1920x795.jpg';

import coverStart2Mobile from './images/1_Cover Startseite/Mobile/210081_us_7016_720x370.jpg';
import coverStart2Tablet from './images/1_Cover Startseite/Tablet/210081_us_7016_1140x300.jpg';
import coverStart2Desktop from './images/1_Cover Startseite/Desktop/210081_us_7016_1920x795.jpg';

import coverStart3Mobile from './images/1_Cover Startseite/Mobile/210081_us_7293_720x370.jpg';
import coverStart3Tablet from './images/1_Cover Startseite/Tablet/210081_us_7293_1140x300.jpg';
import coverStart3Desktop from './images/1_Cover Startseite/Desktop/210081_us_7293_1920x795.jpg';

import coverStart4Mobile from './images/1_Cover Startseite/Mobile/210081_us_7805_720x370.jpg';
import coverStart4Tablet from './images/1_Cover Startseite/Tablet/210081_us_7805_1140x300.jpg';
import coverStart4Desktop from './images/1_Cover Startseite/Desktop/210081_us_7805_1920x795.jpg';

import coverStart5Mobile from './images/1_Cover Startseite/Mobile/210081_us_7861_720x370.jpg';
import coverStart5Tablet from './images/1_Cover Startseite/Tablet/210081_us_7861_1140x300.jpg';
import coverStart5Desktop from './images/1_Cover Startseite/Desktop/210081_us_7861_1920x795.jpg';

import coverProblematikMobile from './images/6_Cover Unterseiten/Mobile/210081_us_7016_hires_Problematik_720x270.jpg';
import coverProblematikTablet from './images/6_Cover Unterseiten/Tablet/210081_us_7016_hires_Problematik_1140x250.jpg';
import coverProblematikDesktop from './images/6_Cover Unterseiten/Desktop/210081_us_7016_hires_Problematik_1920x365.jpg';

import coverAffolterModelMobile from './images/6_Cover Unterseiten/Mobile/210081_us_7673_hires_AffolterModell_720x270.jpg';
import coverAffolterModelTablet from './images/6_Cover Unterseiten/Tablet/210081_us_7673_hires_AffolterModell_1140x250.jpg';
import coverAffolterModelDesktop from './images/6_Cover Unterseiten/Desktop/210081_us_7673_hires_AffolterModell_1920x365.jpg';

import coverKurseMobile from './images/6_Cover Unterseiten/Mobile/210081_us_7096_hires_Kurse_720x270.jpg';
import coverKurseTablet from './images/6_Cover Unterseiten/Tablet/210081_us_7096_hires_Kurse_1140x250.jpg';
import coverKurseDesktop from './images/6_Cover Unterseiten/Desktop/210081_us_7096_hires_Kurse_1920x365.jpg';

import coverAbklaerungMobile from './images/6_Cover Unterseiten/Mobile/210081_us_7614_hires_Abklaerung_720x270.jpg';
import coverAbklaerungTablet from './images/6_Cover Unterseiten/Tablet/210081_us_7614_hires_Abklaerung_1140x250.jpg';
import coverAbklaerungDesktop from './images/6_Cover Unterseiten/Desktop/210081_us_7614_hires_Abklaerung_1920x365.jpg';

import coverBeratungMobile from './images/6_Cover Unterseiten/Mobile/210081_us_7805_hires_Beratung_720x270.jpg';
import coverBeratungTablet from './images/6_Cover Unterseiten/Tablet/210081_us_7805_hires_Beratung_1140x250.jpg';
import coverBeratungDesktop from './images/6_Cover Unterseiten/Desktop/210081_us_7805_hires_Beratung_1920x365.jpg';

import coverTherapieMobile from './images/6_Cover Unterseiten/Mobile/210081_us_7861_hires_Therapie_720x270.jpg';
import coverTherapieTablet from './images/6_Cover Unterseiten/Tablet/210081_us_7861_hires_Therapie_1140x250.jpg';
import coverTherapieDesktop from './images/6_Cover Unterseiten/Desktop/210081_us_7861_hires_Therapie_1920x365.jpg';

import fokusKurseMobile from './images/2_FokusItems/Mobile/Kurse_600x335.jpg';
import fokusKurseTablet from './images/2_FokusItems/Tablet/Kurse_500x275.jpg';
import fokusKurseDesktop from './images/2_FokusItems/Desktop/Kurse_236x185.jpg';

import fokusMehrwertMobile from './images/2_FokusItems/Mobile/Mehrwert_600x335.jpg';
import fokusMehrwertTablet from './images/2_FokusItems/Tablet/Mehrwert_500x275.jpg';
import fokusMehrwertDesktop from './images/2_FokusItems/Desktop/Mehrwert_236x185.jpg';

import fokusFachartikelMobile from './images/2_FokusItems/Mobile/Fachartikel_600x335.jpg';
import fokusFachartikelTablet from './images/2_FokusItems/Tablet/Fachartikel_500x275.jpg';
import fokusFachartikelDesktop from './images/2_FokusItems/Desktop/Fachartikel_236x185.jpg';

import googelSmall from './images/3_Goggel/Mobile/Goggel_168x146.png';
import googelTablet from './images/3_Goggel/Tablet/Goggel_348x300.png';
import googelDesktop from './images/3_Goggel/Desktop/Goggel_508x438.png';

import sabineAugsteinMobile from './images/4_ContactCard/Mobile/210081_us_1038_135x135_Sabine.jpg';
import sabineAugsteinMobile_2x from './images/4_ContactCard/Mobile/210081_us_1038_135x135_Sabine@2x.jpg';
import sabineAugsteinMobile_4x from './images/4_ContactCard/Mobile/210081_us_1038_135x135_Sabine@4x.jpg';
import sabineAugsteinTablet from './images/4_ContactCard/Tablet/210081_us_1038_150x150_Sabine.jpg';
import sabineAugsteinTablet_2x from './images/4_ContactCard/Tablet/210081_us_1038_150x150_Sabine@2x.jpg';
import sabineAugsteinTablet_4x from './images/4_ContactCard/Tablet/210081_us_1038_150x150_Sabine@4x.jpg';
import sabineAugsteinDesktop from './images/4_ContactCard/Desktop/210081_us_1038_170x170_Sabine.jpg';
import sabineAugsteinDesktop_2x from './images/4_ContactCard/Desktop/210081_us_1038_170x170_Sabine@2x.jpg';
import sabineAugsteinDesktop_4x from './images/4_ContactCard/Desktop/210081_us_1038_170x170_Sabine@4x.jpg';

import placeHolderTeamMobile from './images/5_Portraits/Mobile/210081_us_1038_695x695_Platzhalter.jpg';
import placeHolderTeamTablet from './images/5_Portraits/Tablet/210081_us_1038_515x515_Platzhalter.jpg';
import placeHolderTeamDesktop from './images/5_Portraits/Desktop/210081_us_1038_320x320_Platzhalter.jpg';

import portraitSabineAugsteinMobile from './images/5_Portraits/Mobile/210081_us_1038_695x695_Sabine.jpg';
import portraitSabineAugsteinTablet from './images/5_Portraits/Tablet/210081_us_1038_515x515_Sabine.jpg';
import portraitSabineAugsteinDesktop from './images/5_Portraits/Desktop/210081_us_1038_320x320_Sabine.jpg';

import portraitCatherineMobile from './images/5_Portraits/Mobile/210081_us_2008_695x695_Cathrine.jpg';
import portraitCatherineTablet from './images/5_Portraits/Tablet/210081_us_2008_515x515_Cathrine.jpg';
import portraitCatherineDesktop from './images/5_Portraits/Desktop/210081_us_2008_320x320_Cathrine.jpg';

import portraitTabeaMobile from './images/5_Portraits/Mobile/210081_us_3019_695x695_Tabea.jpg';
import portraitTabeaTablet from './images/5_Portraits/Tablet/210081_us_3019_515x515_Tabea.jpg';
import portraitTabeaDesktop from './images/5_Portraits/Desktop/210081_us_3019_320x320_Tabea.jpg';

import portraitSabineMobile from './images/5_Portraits/Mobile/210081_us_4031_695x695_Sabine.jpg'
import portraitSabineTablet from './images/5_Portraits/Tablet/210081_us_4031_515x515_Sabine.jpg'
import portraitSabineDesktop from './images/5_Portraits/Desktop/210081_us_4031_320x320_Sabine.jpg'

import portraitJrisMobile from './images/5_Portraits/Mobile/210081_us_5003_695x695_Jris.jpg'
import portraitJrisTablet from './images/5_Portraits/Tablet/210081_us_5003_515x515_Jris.jpg'
import portraitJrisDesktop from './images/5_Portraits/Desktop/210081_us_5003_320x320_Jris.jpg'

import portraitLaraMobile from './images/5_Portraits/Mobile/210081_us_1038_695x695_Lara.jpg'
import portraitLaraTablet from './images/5_Portraits/Tablet/210081_us_1038_515x515_Lara.jpg'
import portraitLaraDesktop from './images/5_Portraits/Desktop/210081_us_1038_320x320_Lara.jpg'

//import carouselPlaceHolderSmall from './carousel-placeholder-small.png';

import newsLauraMobile from './images/7_InsideNews/Mobile/LauraSergi.jpg';
import newsLauraTablet from './images/7_InsideNews/Tablet/LauraSergi.jpg';
import newsLauraDesktop from './images/7_InsideNews/Desktop/LauraSergi.jpg';

import newsJahresberichtMobile from './images/7_InsideNews/Mobile/Jahresbericht.jpg';
import newsJahresberichtTablet from './images/7_InsideNews/Tablet/Jahresbericht.jpg';
import newsJahresberichtDesktop from './images/7_InsideNews/Desktop/Jahresbericht.jpg';

import newsGrundsaetzeMobile from './images/7_InsideNews/Mobile/GrundsaetzeAffolterModell.jpg';
import newsGrundsaetzeTablet from './images/7_InsideNews/Tablet/GrundsaetzeAffolterModell.jpg';
import newsGrundsaetzeDesktop from './images/7_InsideNews/Desktop/GrundsaetzeAffolterModell.jpg';

import pdfWesuk2007 from './pdfs/2007_WESUK_Wahrnehmungsprobleme_Erfassung_von_Saeuglingen_und_Kleinkindern.pdf';
import pdfGHG2019 from './pdfs/2019-GHG-news-Herbst_WissenschaftAffolterModell.pdf';
import pdfPortraitAdrianHofer from './pdfs/W_Portrait_AdrianHofer.pdf';
import pdfChristianKuenzler from './pdfs/W_Portrait_ChristianKuenzler.pdf';
import pdfPortraitAlexBubenhofer from './pdfs/W_Portrait_AlexBubenhofer.pdf';
import pdfPortraitBrigittePastewka from './pdfs/W_Portrait_BrigittePastewka.pdf';
import pdfPortraitFamilieHunziker from './pdfs/W_Portrait_FamilieHunziker.pdf';
import pdfPortraitFelicieAffolter from './pdfs/W_Portrait_FelicieAffolter.pdf';
import pdfPortraitIsabelKnechtel from './pdfs/W_Portrait_IsabelKnechtel.pdf';
import pdfPortraitJrisBischof from './pdfs/W_Portrait_JrisBischof.pdf';
import pdfPortraitNico from './pdfs/W_Portrait_Nico.pdf';
import pdfPortraitSabineAugstein from './pdfs/W_Portrait_SabineAugstein.pdf';
import pdfPortraitSamuel from './pdfs/W_Portrait_Samuel.pdf';
import pdfPortraitTabeaWoessner from './pdfs/W_Portrait_TabeaWoessner.pdf';
import pdfPortraitLaraCarlot from './pdfs/W_Portrait_LaraCarlot.pdf';
import pdfPortraitCatrhineSteiger from './pdfs/W_Portrait_CatherineSteiger.pdf';

import pdfJahresbericht from './pdfs/wahrnehmung_jahresbericht_2023.pdf';

import pdfGrundsaetzeAffolter from './pdfs/Grundsaetze_AffolterModells.pdf';

import {
  ArrowRight,
   Download as DownloadIcon,
   Mail as MailIcon,
   Telephone as TelephoneIcon
  } from './icons';
import { Link, NavLink, Redirect, Route, Switch } from 'react-router-dom';

const cover1: ICoverProps = {
  mobileImage: coverStart1Mobile,
  tabletImage: coverStart1Tablet,
  desktopImage: coverStart1Desktop,
  objectPosition: "65% top"
};

const cover2: ICoverProps = {
  mobileImage: coverStart2Mobile,
  tabletImage: coverStart2Tablet,
  desktopImage: coverStart2Desktop
};

const cover3: ICoverProps = {
  mobileImage: coverStart3Mobile,
  tabletImage: coverStart3Tablet,
  desktopImage: coverStart3Desktop,
  objectPosition: "60% center"
};

const cover4: ICoverProps = {
  mobileImage: coverStart4Mobile,
  tabletImage: coverStart4Tablet,
  desktopImage: coverStart4Desktop,
  objectPosition: "60% center"
};

const cover5: ICoverProps = {
  mobileImage: coverStart5Mobile,
  tabletImage: coverStart5Tablet,
  desktopImage: coverStart5Desktop
};

const coverProblematik: ICoverProps = {
  mobileImage: coverProblematikMobile,
  tabletImage: coverProblematikTablet,
  desktopImage: coverProblematikDesktop
};

const coverAffolterModel: ICoverProps = {
  mobileImage: coverAffolterModelMobile,
  tabletImage: coverAffolterModelTablet,
  desktopImage: coverAffolterModelDesktop,
  objectPosition: "80% center"
};

const coverKurse: ICoverProps = {
  mobileImage: coverKurseMobile,
  tabletImage: coverKurseTablet,
  desktopImage: coverKurseDesktop,
  objectPosition: "65% center"
};

const coverAbklaerung: ICoverProps = {
  mobileImage: coverAbklaerungMobile,
  tabletImage: coverAbklaerungTablet,
  desktopImage: coverAbklaerungDesktop,
  objectPosition: "60% center"
};

const coverBeratung: ICoverProps = {
  mobileImage: coverBeratungMobile,
  tabletImage: coverBeratungTablet,
  desktopImage: coverBeratungDesktop
};

const coverTherapie: ICoverProps = {
  mobileImage: coverTherapieMobile,
  tabletImage: coverTherapieTablet,
  desktopImage: coverTherapieDesktop,
  objectPosition: "90% center"
};

interface IColumnProps {
  className?: string;
}

export class ColumnLeft extends React.Component<IColumnProps> {
  render() {
    return <div className={`column column-left ${this.props.className || ""}`}>
      { this.props.children }
    </div>
  }
}

export class ColumnRight extends React.Component<IColumnProps> {
  render() {
    return <div className={`column column-right ${this.props.className || ""}`}>
      { this.props.children }
    </div>
  }
}

interface ISummaryProps {
  title: string;
}

export class Summary extends React.Component<ISummaryProps> {
  render() {
    return <summary>
       { this.props.title }
       <div className="arrow" />
    </summary>
  }
}

interface IDetailsProps extends ISummaryProps{
  open?: boolean;
  id?: string;
}

export class Details extends React.Component<IDetailsProps> {
  handleToggle()
  {
    ReactGA.event({
      category: 'Navigation',
      action: 'Toggle details',
      label: this.props.title
    });
  }

  render() {
    return <details open={this.props.open} id={this.props.id} onToggle={this.handleToggle.bind(this)}>
       <Summary {...this.props} />
       { this.props.children }
    </details>
  }
}

interface IArticleProps {
  title: string;
}

export class Article extends React.Component<IArticleProps> {
  render() {
    return <article className="page-content">
      <h1>{this.props.title}</h1>
      { this.props.children }
    </article>
  }
}

interface ISectionProps {
  title?: string;
  className?: string;
  id?: string;
  forceBreak?: boolean;
}

export class Section extends React.Component<ISectionProps>{
  render() {
    return <section className={this.props.className} id={this.props.id}>
      { this.props.forceBreak ? <hr/> : null }
      { this.props.title ? <h2>{this.props.title}</h2> : <hr/> }
      { this.props.children }
    </section>
  }
}

interface IAsideProps {
  title?: string;
}

export class Aside extends React.Component<IAsideProps>{
  render() {
    return <aside>
      { this.props.title && <h3>{this.props.title}</h3> }
      { this.props.children }
    </aside>
  }
}

interface ICoverProps {
  mobileImage?: string;
  tabletImage?: string;
  desktopImage?: string;
  objectPosition?: string;
}

export class Cover extends React.Component<ICoverProps> {
render(){
  const objectPosition = this.props.objectPosition ? { objectPosition: this.props.objectPosition } : undefined;

  return (
    <div className="page-cover">
      <picture>
        <source media="(min-width: 1140px)" srcSet={this.props.desktopImage ?? placeHolderDesktop} />
        <source media="(min-width: 720px)" srcSet={this.props.tabletImage ?? placeHolderTablet} />
        <img src={this.props.mobileImage ?? placeHolderSmall} style={objectPosition} alt="" />
      </picture>
    </div>
    );
  }
}

interface IMenuItemProps {
  id?: string;
  link: string;
  name: string;
}

interface IContentMenuProps {
  id?: string;
  cover?: boolean;
  items: IMenuItemProps[];
  useActive?: boolean;
}

export class ContentMenu extends React.Component<IContentMenuProps> {
  public static defaultProps = {
    useActive: true,
  };

  renderLink(item: IMenuItemProps){
    return this.props.useActive ?
    <NavLink to={item.link} key={item.link}>{item.name}</NavLink> :
    <Link to={item.link} key={item.link}>{item.name}</Link>;
  }

  render(){
    return (
      <div className={ `content-menu-container ${this.props.cover ? "cover": "" }` }>
      <nav className="content-menu" id={this.props.id}>
        {this.props.items.map(item => this.renderLink(item))}
      </nav>
      { this.props.children }
      </div>
    );
  }
}

interface IActiveButtonProps {
  buttonId: IntroButtonKey;
  activeId: IntroButtonKey;
  onClick: (id: IntroButtonKey) => void;
}

export class ActiveButton extends React.Component<IActiveButtonProps> {
  render(){
    return (
      <button onClick={ () => this.props.onClick(this.props.buttonId) } className={this.props.activeId === this.props.buttonId ? "active" : "" }>
        { this.props.children }
      </button>
    )};
}

interface IExternalLinkProps {
  to: string;
  style?: CSSProperties;
}

export class ExternalLink extends React.Component<IExternalLinkProps>{
  handleClick()
  {
    ReactGA.event({
      category: 'Navigation',
      action: 'External link',
      label: this.props.to
    });
  }

  render(){
    return (
      <Link to={ {pathname: this.props.to} } target="_blank" rel="noopener noreferrer" className="btn btn-external" style={this.props.style} onClick={this.handleClick.bind(this)}>{ this.props.children }</Link>
    )
  };
}

type IntroButtonKey = "none" | "abklaerung" | "therapie" | "beratung" | "kurse";

interface IIntroContentMenuState {
  selected: IntroButtonKey;
  active: IntroButtonKey;
  announcement?: IAnnouncementBubbleProps;
}

interface IIntroContentMenuProps extends IContentMenuProps {
  interval?: number;
}

export class IntroContentMenu extends React.Component<IIntroContentMenuProps, IIntroContentMenuState> {
  public static defaultProps = {
    interval: 5,
  };

  private timer: any;
  private firstTime: boolean;
  private dataProvider: AnnouncementDataProvider = new AnnouncementDataProvider();

  constructor(props: any) {
    super(props);

    this.state = {
      selected: "none",
      active: "none",
      announcement: undefined
    }

    this.firstTime = true;
  }

  componentDidMount(){
    this.timer = setInterval(this.handleNext.bind(this), (this.props.interval || 0) * 1000);
    this.dataProvider.getNextAnnouncement().then( data => 
      {
        this.setState({
          announcement: data
        })
      }
    );
  }

  componentWillUnmount(){
    this.resetTimer();
  }

  handleClick(id: IntroButtonKey){
    this.setState({active: id, selected: id});
    this.resetTimer();
  }

  handleNext()
  {
    const items: Array<IntroButtonKey> = ["abklaerung", "therapie", "beratung", "kurse"];

    var index = items.indexOf(this.state.selected);

    index++

    if(index >= items.length) index = 0;

    var item = items[index];

    if(!this.firstTime)
    {
      this.setState({selected: item, active: item});
    }
    else{
      this.setState({selected: item});

      setTimeout(() => this.setState({active: item}), 1000);

      this.firstTime = false;
    }
  }

  resetTimer(){
    clearInterval(this.timer);
  }

  render(){
    return (
      <div className={ `content-menu-container ${this.props.cover ? "cover": "" }` }>
      <nav className="content-menu">        
        {this.props.items.map(item => <ActiveButton key={item.id} buttonId={item.id as IntroButtonKey ?? "none"} activeId={this.state.selected} onClick={this.handleClick.bind(this)}>{item.name}</ActiveButton>)}
      </nav>
      { this.props.children }
      <Spoiler spoilderId="abklaerung" activeId={this.state.active}>
        <p>«Sonja ist unruhig und kann schlecht bei einer Sache bleiben. Bei Alltäglichem, wie z.B. beim Anziehen, benötigt sie enge Begleitung. Mit Erwachsenen spielt sie sehr schön, allein oder mit gleichaltrigen Kindern geht das kaum.»</p>
        <Link to="/angebot/abklaerung" className="btn btn-internal">Mehr</Link>
      </Spoiler>
      <Spoiler spoilderId="therapie" activeId={this.state.active}>
        <p>«Wir sind froh, dass die Kinderärztin uns schon früh auf gewisse Probleme von Mario hingewiesen hat. In der <Link to="/angebot/therapie">Therapie</Link> ist er sehr konzentriert mit dabei. Wir sind dankbar für die vielen Ideen der Therapeutin, was wir im Alltag verändern können.»</p>
        <Link to="/angebot/therapie" className="btn btn-internal">Mehr</Link>
      </Spoiler>
      <Spoiler spoilderId="beratung" activeId={this.state.active}>
        <p>«Unsere Institution betreut und fördert Menschen mit einer Autismus-Spektrum-Störung ASS. Einer unserer Klienten zeigt in verschiedenen Situationen sehr herausforderndes Verhalten. Wir brauchen echt Unterstützung, damit wir angemessen reagieren und agieren.»</p>
        <Link to="/angebot/beratung" className="btn btn-internal">Mehr</Link>
      </Spoiler>
      <Spoiler spoilderId="kurse" activeId={this.state.active}>
        <p>«Als Fachpersonen suchen wir eine Weiterbildung zum Thema <Link to="/wahrnehmungsproblematik">Wahrnehmung</Link>. Was wir brauchen, ist nicht nur theoretisches Wissen. Die Schulung muss mit der Praxis verknüpft und in unserem Alltag umsetzbar sein.»</p>
        <Link to="/angebot/kurse" className="btn btn-internal">Mehr</Link>
      </Spoiler>
      { this.state.announcement && <AnnouncementBubble {...this.state.announcement} /> }
      </div>
    );
  }
}

export class AnnouncementBubble extends React.Component<IAnnouncementBubbleProps> {
  render() {
    return this.props.date <= new Date() ? null : (
      <div className="announcement-bubble">
        Jetzt aktuell:<br/>
        <span className="title">{this.props.title}</span>
        {this.props.date.toLocaleDateString("de-CH")}<br/>
        <ExternalLink to={this.props.link}><ArrowRight />Mehr</ExternalLink>
      </div>
    );
  }
}

interface ISpoilerProps {
  spoilderId: IntroButtonKey;
  activeId: IntroButtonKey;
}

export class Spoiler extends React.Component<ISpoilerProps> {
  render(){
    return this.props.spoilderId === this.props.activeId && (
      <div className="spoiler">
        { this.props.children }
      </div>
    );
  }
}

export class Intro extends React.Component {
  render() {
    return (
      <div className="content-intro">
        { this.props.children }
      </div>
    );
  }
}

interface ICarouselItemProps {
  title: string;
  text: string;
  to: string;
  mobileImage: string;
  tabletImage: string;
  desktopImage: string;
}

export class CarouselItem extends React.Component<ICarouselItemProps> {
  render() {
    return (
      <div className="carousel-item">
        <picture>
          <source media="(min-width: 1140px)" srcSet={this.props.desktopImage} />
          <source media="(min-width: 720px)" srcSet={this.props.tabletImage} />
          <img src={this.props.mobileImage} alt="" />
        </picture>
        <h3>{this.props.title}</h3>
        <p>{this.props.text}</p>
        <Link to={this.props.to} className="btn btn-internal">Mehr</Link>
      </div>
    );
  }
}

export class KursItem extends React.Component<IKursItemProps> {
  public static defaultProps = {
    linkText: "Anmelden"
  };

  render() {
    return (
      <div className="carousel-item course-item" itemScope itemType='http://schema.org/Event'>
        { this.props.pdf ? <meta itemProp='url' content={this.props.pdf.to} /> : null }
        { this.props.startDate ? <meta itemProp='startDate' content={this.props.startDate.toJSON()} /> : null }
        { this.props.endDate ? <meta itemProp='endDate' content={this.props.endDate.toJSON()} /> : null }
        { this.props.location ? <meta itemProp='location' content={this.props.location} /> : null }
        { this.props.name ? <meta itemProp='name' content={this.props.name} /> : null }
        <div className="picture-container">
          <picture>
            <source media="(min-width: 1140px)" srcSet={this.props.desktopImage ?? fokusKurseDesktop} />
            <source media="(min-width: 720px)" srcSet={this.props.tabletImage ?? fokusKurseTablet} />
            <img src={this.props.mobileImage ?? fokusKurseMobile} alt="" />
          </picture>
          { this.props.planned ? <div><h3>Geplant</h3><p>Anmeldung in Kürze möglich!</p></div> : null }
          { this.props.full ? <div><h3>Ausgebucht</h3><p>weitere Kursdaten in Kürze!</p></div> : null }
        </div>
        <h3>{this.props.title}</h3>
        <p className="teaser">{this.props.teaser}</p>
        <p>{this.props.text}</p>
        <p>{this.props.date}</p>
        { this.props.pdf ? <ExternalLink to={this.props.pdf.to}><ArrowRight />{this.props.pdf.text ?? this.props.linkText}</ExternalLink> : null }
        { this.props.anmeldung && !this.props.full && !this.props.planned ? <Link to={this.props.anmeldung.to} className="btn btn-internal">{this.props.anmeldung.text ?? this.props.linkText}</Link> : null }        
      </div>
    );
  }
}

export class CustomCarousel extends React.Component {
  render() {
    const breakPoints = [
      { width: 1, itemsToShow: 1, showArrows: false },
      { width: 720 - 60, itemsToShow: 2, itemsToScroll: 2, showArrows: false },
      { width: 1080, itemsToShow: 3, itemsToScroll: 3 }
    ];

    return (
      <CustomizedCarousel breakPoints={breakPoints}>
        { this.props.children }
      </CustomizedCarousel>
    )
  };
}

interface IKursCarouselProps {
  kurse: IKursItemProps[];
  noKurseMessage?: string;
}

export class KursCarousel extends React.Component<IKursCarouselProps> {
  compareCourseStartDate(course1: IKursItemProps, course2: IKursItemProps): number{
    if(course1.startDate === undefined || course2.startDate === undefined) return 0;
    if(course1.startDate > course2.startDate){
      return 1;
    }
    if(course1.startDate < course2.startDate){
      return -1;
    }
    return 0;
  }

  sortFn2(course1: IKursItemProps, course2: IKursItemProps) :number { 
    if(course1.order === course2.order || course1.order ===  undefined || course2.order === undefined )
    {
      return this.compareCourseStartDate(course1, course2);
    }else if(course1.order > course2.order){
      return 1;
    }
    else if(course1.order < course2.order){
      return -1;
    }
    return 0;
  }
  
  render() {
    var kurse = this.props.kurse.filter(item => item.startDate === undefined ? true : item.startDate >= new Date());
    return (
      <Fragment>
        { (kurse.length <= 0) ? 
        <p>{this.props.noKurseMessage ?? "Zur Zeit finden keine Kurse statt."}</p> : 
        <CustomCarousel>
          {kurse.sort(this.sortFn2.bind(this)).map(item => <KursItem key={item.id} {...item}/>)}
        </CustomCarousel> }
      </Fragment>      
    )
      
  }
}

interface IBreakpoint {
  width: number;
  itemsToShow?: number | undefined;
  itemsToScroll?: number | undefined;
}

interface ICustomizedCarousel {
  breakPoints?: IBreakpoint[]
}

export class CustomizedCarousel extends React.Component<ICustomizedCarousel> {
  renderArrows = (params: RenderArrowProps) => {
    return <button onClick={params.onClick} disabled={params.isEdge} className={`carousel-arrow ${params.type === "PREV" ? "left" : "right"}`} aria-label={params.type === "PREV" ? "Previous slide" : "Next slide"}>
      {params.type === "PREV" && <div className="chevron chevron-left" />}
      {params.type === "NEXT" && <div className="chevron chevron-right" />}
    </button>
  };

  render() {
    return (
      <Carousel itemsToShow={1} isRTL={false} breakPoints={this.props.breakPoints} renderArrow={this.renderArrows} showEmptySlots={true}>
      { this.props.children }
      </Carousel>
    )
  };
}

export class ContactCard extends React.Component{
  render() {
    let createSourceSet = (image_1x: string, image_2x: string, image_4x: string): string => {
      return image_1x + ' 1x, ' + image_2x + ' 2x,' + image_4x + ' 4x';
    };

    return (
      <aside className="contact-card">
        <p>Gerne beantworte ich Ihre Anfrage. Bitte rufen Sie mich an oder schreiben Sie mir:</p>
        <div className="contact-info">
          <picture>
            <source media="(min-width: 1140px)" srcSet={createSourceSet(sabineAugsteinDesktop, sabineAugsteinDesktop_2x, sabineAugsteinDesktop_4x)} />
            <source media="(min-width: 720px)" srcSet={createSourceSet(sabineAugsteinTablet, sabineAugsteinTablet_2x, sabineAugsteinTablet_4x)} />
            <img src={sabineAugsteinMobile} srcSet={createSourceSet(sabineAugsteinMobile, sabineAugsteinMobile_2x, sabineAugsteinMobile_4x)} alt="Sabine Augstein" />
          </picture>
          <a href="tel:+41712220234"><span className="desktop">Telefon </span><TelephoneIcon /></a>
          <a href="mailto:info@wahrnehmung.ch"><span className="desktop">E-Mail </span><MailIcon /></a>
        </div>
        <p className="contact-name">Sabine Augstein,<br/>
        Leitung wahrnehmung.ch</p>
      </aside>
    );
  }
}

interface IOfferPageState {
  width: number;
  height: number;
}

export class StartPage extends React.Component<{}, IOfferPageState> {

  private coverIndex: number;

  constructor(props: any) {
    super(props);

    this.state = {
      width: 0,
      height: 0
    }
    
    this.coverIndex = Math.floor(Math.random() * 4);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    this.coverIndex = Math.floor(Math.random() * 4);
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    const items = [
      {id: 1, title: 'Unser aktuelles Kursangebot', text: 'Die Kurse zum Affolter-Modell® sind in Grundkurse, (bestehend aus Einführungs-, Basis- und Zertifizierungsmodul), weiterführende Kurse und Spezialkurse aufgeteilt. Hier finden Sie die aktuellen Termine und Infos dazu.', to: "/angebot/kurse", mobileImage: fokusKurseMobile, tabletImage: fokusKurseTablet, desktopImage: fokusKurseDesktop},
      {id: 2, title: 'Die Grundsätze des Affolter-Modells®', text: 'Der Artikel von Adrian Hofer und Tabea Wössner gibt einen umfassenden Überblick über die Forschungsarbeit von Dr. Félicie Affolter, in der sie mit ihren Mitarbeiterinnen und Mitarbeitern ein Entwicklungsmodell erarbeitet. Dieses Modell erklärt sowohl unauffällige als auch auffällige Entwicklungsverläufe.', to: "/inside",  mobileImage: fokusFachartikelMobile, tabletImage: fokusFachartikelTablet, desktopImage: fokusFachartikelDesktop},
      {id: 3, title: 'Schaffen Sie Mehrwert', text: 'Mit Ihrer Spende helfen Sie mit, dass Menschen mit Wahrnehmungsproblemen ihren Alltag meistern können und Angehörige und Fachpersonen entsprechende Unterstützung bekommen.', to: "/inside#spenden",  mobileImage: fokusMehrwertMobile, tabletImage: fokusMehrwertTablet, desktopImage: fokusMehrwertDesktop}
    ];

    const menuItems = [
      { id: "abklaerung", name: "Abklärung", link: "#" },
      { id: "therapie", name: "Therapie", link: "#" },
      { id: "beratung", name: "Beratung", link: "#" },
      { id: "kurse", name: "Kurse", link: "#" }
    ];

    const covers: ICoverProps[] = [
      cover1,
      cover2,
      cover3,
      cover4,
      cover5
    ];

    const currentCover: ICoverProps = covers[this.coverIndex];

    return [
      <Helmet key="helmet">
        <title>Stiftung wahrnehmung.ch</title>
      </Helmet>,
      <Article title="Das Zusammenspiel von Hören, Sehen, Spüren, Denken." key="article">
        <IntroContentMenu items={menuItems} cover={true}>
          <Cover {...currentCover} />
        </IntroContentMenu>
        <Intro>
          <Details title="Für Betroffene" open={ this.state.width > 1140 && this.state.height > this.state.width }>
            <p>Unsere Stiftung fokussiert auf Kinder, Jugendliche und Erwachsene mit <Link to="/wahrnehmungsproblematik">Wahrnehmungsproblemen</Link>, wie sie im Zusammenhang mit Sprachentwicklungsstörungen, Autismus-Spektrum-Störung ASS und Entwicklungsproblemen auftreten können. Wir klären ab und bieten individuelle <Link to="/angebot/therapie">Therapien</Link> an.</p>
            <div>
              <Link to="/wahrnehmungsproblematik" className="btn btn-internal">Wahrnehmungsprobleme</Link><br />
              <Link to="/angebot/abklaerung" className="btn btn-internal">Abklärung</Link><br />
              <Link to="/angebot/therapie" className="btn btn-internal">Therapie</Link>
            </div>
          </Details>
          <Details title="Für Eltern" open={ this.state.width > 1140 && this.state.height > this.state.width }>
            <p>Eltern von Kindern mit <Link to="/wahrnehmungsproblematik">Wahrnehmungsproblemen</Link> brauchen Unterstützung. Wir geben in Beratungsgesprächen wichtige Impulse für den Umgang mit ihren Kindern und beziehen sie in die <Link to="/angebot/therapie">Therapie</Link> mit ein.</p>
            <div>
              <Link to="/wahrnehmungsproblematik" className="btn btn-internal">Wahrnehmungsprobleme</Link><br />
              <Link to="/angebot/therapie" className="btn btn-internal">Therapie</Link><br />
              <Link to="/angebot/beratung" className="btn btn-internal">Beratung</Link>
            </div>
          </Details>
          <Details title="Für Fachpersonen" open={ this.state.width > 1140 && this.state.height > this.state.width }>
            <p>Wir schulen und beraten Fachpersonen, die in ihrer Arbeit mit Kindern, Jugendlichen und Erwachsenen auf irgendeine Weise mit dem Thema <Link to="/wahrnehmungsproblematik">Wahrnehmung</Link> in Berührung kommen.</p>
            <div>
              <Link to="/wahrnehmungsproblematik" className="btn btn-internal">Wahrnehmung</Link><br />
              <Link to="/angebot/beratung" className="btn btn-internal">Beratung</Link><br />
              <Link to="/angebot/kurse" className="btn btn-internal">Kurse</Link>
            </div>
          </Details>
        </Intro>
        <Section title="Unser Fokus" className="alternate-background">
          <CustomCarousel>
            {items.map(item => <CarouselItem key={item.id} {...item}/>)}
          </CustomCarousel>
        </Section>
      </Article>
    ];
  }
}

export class ProblematikPage extends React.Component {
  render() {
    return [
      <Helmet key="helmet">
        <title>Problematik</title>
      </Helmet>,
      <Article title="Wahrnehmungsprobleme wahrnehmen." key="article">
        <Cover {...coverProblematik} />
        <Section title="Wahrnehmung?" id="wahrnehmung">
          <ColumnLeft>
            <p className="teaser">Wahrnehmung ist die Fähigkeit, Reize aus der Umwelt über unsere Sinne aktiv aufzunehmen, zu verarbeiten und ihnen Sinn zu geben. Die Verarbeitung dieser Sinneseindrücke läuft organisiert ab und umfasst eine ganze Reihe von Prozessen:</p>
            <p>Da wir unzähligen Eindrücken ausgesetzt sind, müssen diese gefiltert werden. Nicht alle Reize in einer Situation sind gleich wichtig für uns. Wir richten unsere Aufmerksamkeit auf bestimmte Reize und wählen so die für uns bedeutsamen aus. Diese Auswahl erfolgt durch unsere aktuellen Ziele, unsere Erfahrung, Bedürfnisse und Vorlieben und ist in eine augenblickliche Situation eingebettet.</p>
            <p>Diese Ausrichtung ist bereits eine wichtige Organisationsleistung. Wir suchen eigentlich ständig nach für uns bedeutsamen Reizen – wir sprechen auch von Informationssuche. Laufend passen wir diese Prozesse der Informationssuche an die gegebene Situation und unser Wirken darin an.</p>
          </ColumnLeft>
          <ColumnRight>
            <Details title="Arten von Wahrnehmung">
              <p>Es gibt unterschiedliche Arten der Wahrnehmung. In unserer Arbeit fokussieren wir primär auf haptische Wahrnehmungsprozesse. Sie sind wichtige Voraussetzungen für die multimodale Wahrnehmung und weitere kognitive, emotionale und soziale Prozesse, wie zum Beispiel:</p>
              <ul>
                <li>Hypothesen bilden</li>
                <li>Schlussfolgerungen ziehen</li>
                <li>Wichtiges von Unwichtigem unterscheiden</li>
                <li>Sich auf etwas freuen können</li>
                <li>Erwartungen aufbauen</li>
                <li>Mit Frustrationen konstruktiv umgehen</li>
                <li>Wut erleben und in Grenzen halten</li>
                <li>Toleranz üben</li>
                <li>Das Verhalten und die Emotionen des Gegenübers verstehen und sich der Situation entsprechend anpassen</li>
              </ul>
            </Details>
            <ContactCard />
          </ColumnRight>
        </Section>
        <Section title="Wahrnehmungsprobleme?" id="wahrnehmungsprobleme">
          <ColumnLeft>
            <p className="teaser">Menschen mit Problemen in der Wahrnehmung unterscheiden sich auf den ersten Blick nicht unbedingt von Menschen ohne diese Probleme.</p>
            <p>Sie sind in ihren Möglichkeiten, Informationen angemessen zu suchen und zu verarbeiten, eingeschränkt. Sie können in einer bestimmten Situation wichtige Informationen nur bruchstückhaft erkennen und nicht richtig einordnen.</p>
            <p>Aufgaben ausserhalb ihres vertrauten Rahmens können eine Herausforderung sein.</p>
          </ColumnLeft>
          <ColumnRight>
            <Details title="Reaktionen von Betroffenen">
              <p>In solchen Situationen reagieren Betroffene für ihr Umfeld oft unangemessen und störend.</p>
              <p>Zum Beispiel mit</p>
              <ul>
                <li>massiven Wutanfällen</li>
                <li>Essproblemen</li>
                <li>motorischen Ungeschicklichkeiten</li>
                <li>Schlafstörungen</li>
                <li>Ängsten</li>
              </ul>
              <p>Sie wirken auf ihre Umwelt in Extremen, zum Beispiel:</p>
              <ul>
                <li>Sie sind eckig/unangepasst oder aber extrem angepasst.</li>
                <li>Sie schauen nur zu oder verfallen in blinden Aktionismus.</li>
                <li>Sie sind distanzlos oder aber extrem zurückgezogen.</li>
                <li>Sie haben keine Ausdauer oder zeigen monoton-gleichbleibende Tätigkeiten über längere Zeit.</li>
                <li>Sie klettern wie ein Eichhörnchen, können aber nicht über eine Wiese laufen.</li>
                <li>Sie sprechen schlecht oder unablässig – über Spezialinteressen oder in Floskeln.</li>
              </ul>
            </Details>
            <Aside>
              <p>Formen von Wahrnehmungsproblemen finden sich bei Sprachentwicklungsstörungen, Entwicklungsverzögerungen und Autismus-Spektrum-Störung ASS</p>
              <ExternalLink to="https://www.autismus.ch"><ArrowRight />autismus.ch</ExternalLink>
            </Aside>
          </ColumnRight>
        </Section>
      </Article>
    ]
  };
}

export class AffolterModellPage extends React.Component {
  render() {
    return [
      <Helmet key="helmet">
        <title>Affolter-Modell®</title>
      </Helmet>,
      <Article title="Affolter-Modell&reg;: Gespürte Interaktionstherapie." key="article">
        <Cover {...coverAffolterModel} />
        <Section title="Kurz und bündig erklärt." id="erklaerung">
          <ColumnLeft>
            <p className="teaser">Das Affolter-Modell®, auch «Gespürte Interaktionstherapie» genannt, wurde von der Schweizer Psychologin, Audiologin und Logopädin Dr. Félicie Affolter entwickelt.</p>
            <p>Das Modell besteht aus einem Entwicklungsmodell und einer Therapiemethode. Es beruht auf 40 Jahren Erfahrung in der Arbeit mit Menschen mit <Link to="/wahrnehmungsproblematik">Wahrnehmungsproblemen</Link>, die im Zusammenhang stehen mit erschwertem Spracherwerb, tiefgreifenden Entwicklungsstörungen wie Autismus-Spektrum-Störung ASS, erworbener Hirnschädigung und degenerativen Erkrankungen des Nervensystems.</p>
            <p>In den vergangenen Jahren wurde das Affolter-Modell® immer wieder überarbeitet und in der Praxis und <Link to="/ueber-uns#forschungsarbeit">Forschung</Link> weiterentwickelt.</p>
          </ColumnLeft>
          <ColumnRight>
            <Details title="Das Entwicklungsmodell">
              <p>Jede Entwicklung beruht auf einer Interaktion zwischen Mensch und Umwelt.<br/>
              Im Rahmen einer unauffälligen Entwicklung kommt das Kind durch gespürte Auseinandersetzung mit der dinglichen und lebenden Umwelt zu taktil-kinästhetischer Interaktionserfahrung. Diese Interaktionserfahrung ist für das Lernen und die weitere Entwicklung von grosser Bedeutung.</p>
              <p>Ist diese Interaktion aufgrund von <Link to="/wahrnehmungsproblematik">Wahrnehmungsproblemen</Link> eingeschränkt, ist auch die Entwicklung auffällig.</p>
            </Details>
            <Details title="Die Therapiemethode">
              <p>Die gespürte Interaktionstherapie unterstützt das Lernen im Alltag. Konkret bedeutet das: Therapeut:innen führen die Hände und den Körper der Patient:innen in Alltagssituationen, um Handlungskompetenzen aufzubauen, ihre Wahrnehmung zu fördern und alltägliche Aufgaben zu lösen.</p>
              <p>Die Therapiemethode zeigt Erfolge bei entwicklungsauffälligen Babys und Kleinkindern, Entwicklungsstörungen des Sprechens und der Sprache, der Motorik und bei kombinierten Störungen. Auch bei Lernschwierigkeiten, tiefgreifenden Entwicklungsstörungen und bei Schädelhirntraumata oder Schlaganfällen findet die Methode Anwendung.</p>
            </Details>
            <Aside>
              <p>Uns ist wichtig, dass Eltern an der Therapie teilnehmen und in ein partnerschaftliches Miteinander miteinbezogen werden.</p>
            </Aside>
          </ColumnRight>
        </Section>
        <Section title="Die Gründerin." id="gruenderin">
          <ColumnLeft>
            <p className="teaser">Félicie Affolter (*1926)</p>
            <p>In St.&nbsp;Gallen geboren und aufgewachsen, entwickelt die Psychologin, Audiologin und Logopädin in den 70-er Jahren das Affolter-Modell®, das noch heute Fundament der Stiftung&nbsp;wahrnehmung.ch ist.</p>
          </ColumnLeft>
          <ColumnRight>            
            <Aside title="Ein Leben für die Wahrnehmung">
              <p>Mehr über die Stiftungsgründerin lesen Sie im Portrait «Ein Leben für die Wahrnehmung: Dr. Félicie Affolter Sequenzen aus ihrem Werdegang, ihrer Forschungsarbeit und ihrem Alltag».</p>
              <ExternalLink to={pdfPortraitFelicieAffolter}><DownloadIcon/>Download PDF</ExternalLink>
            </Aside>
          </ColumnRight>
        </Section>
      </Article>
    ];
  }
}

interface IContactFormState {
  name: string;
  firstName: string;
  address: string;
  place: string;
  phone: string;
  email: string;
  message: string;

  sent: boolean;
}

export class ContactForm extends React.Component<{}, IContactFormState> {
  constructor(props: any) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      name: "",
      firstName: "",
      address: "",
      place: "",
      phone: "",
      email: "",
      message: "",

      sent: false
    };
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    } as unknown as IContactFormState);
  }

  handleSubmit(event: any) {
    fetch('/api/contact', {
      method: "POST", 
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state) 
    })
      .then(response => {
        if(response.ok){
          this.setState({
            sent: true
          });

          ReactGA.event({
            category: 'Contact',
            action: 'Form sent'
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);

        ReactGA.event("exception", 
        {
          description: 'Could not send contact form. ' + error.message,
          fatal: true
        });
      });

    event.preventDefault();
  }

  handleInvalidPlace(event: any)
  {
    event.target.setCustomValidity('Bitte PLZ & Ort im Format "CH-9000 St. Gallen" oder "9000 St. Gallen" eingeben.');
  }

  handleInvalidPhone(event: any)
  {
    event.target.setCustomValidity('Bitte Telefonnummer im Format "+41 71 222 02 34" oder "071 222 02 34" eingeben.');
  }

  render() {
    return (
      <Fragment>
      {
      this.state.sent ?
      <p className="teaser">Vielen Dank für Ihre Kontaktaufnahme. Sie erhalten demnächst eine Mailbestätigung.</p> : 
      <form onSubmit={this.handleSubmit}>
        <input type="text" name="name" onChange={this.handleInputChange} value={this.state.name} placeholder="Name*" required />
        <input type="text" name="firstName" onChange={this.handleInputChange} value={this.state.firstName} placeholder="Vorname" />
        <input type="text" name="address" onChange={this.handleInputChange} value={this.state.address} placeholder="Addresse" />
        <input type="text" name="place" onChange={this.handleInputChange} value={this.state.place} placeholder="PLZ &amp; Ort" pattern="^[\w-]*[0-9]+[ ].+" onInvalid={this.handleInvalidPlace} title="Eingabe im Format 'CH-9000 St. Gallen oder 9000 St. Gallen'." />
        <input type="tel" name="phone" onChange={this.handleInputChange} value={this.state.phone} placeholder="Telefonnummer" pattern="^[+0-9][0-9 ]+" onInvalid={this.handleInvalidPhone} title="Eingabe im Format '+41 71 222 02 34' oder '071 222 02 34'." />
        <input type="email" name="email" onChange={this.handleInputChange} value={this.state.email} placeholder="E-Mail*" required />
        <textarea name="message" onChange={this.handleInputChange} value={this.state.message} placeholder="Mitteilung*" required />
        <button type="submit" className="btn btn-internal">Senden</button>
      </form>
    }
    </Fragment>
    );
  }
}

interface IYoutubeProps {
  embedId: string;
}

export class Youtube extends React.Component<IYoutubeProps> {
  render () {
    return (
      <div className="video-container">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${this.props.embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    )
  };
}

interface ITeamCardsProps {
  id: number;
  name: string;
  description: string;
  portraitUrl?: string;
  mail?: string;
  mobileImage?: string;
  tabletImage?: string;
  desktopImage?: string;
}

export class TeamCard extends React.Component<ITeamCardsProps> {
  
  nl2br(text: string)
  {
    return text
      .split(/(?:\r\n|\r|\n)/)
      .map(function(line, index) {
        if (true) {
          return <Fragment key={index}>{line}<br/></Fragment>;
        }
        return line;
      });
  }
    
  render(){
    return (
      <figure className="image-teaser" key={this.props.id}>
        <picture>
          <source media="(min-width: 1140px)" srcSet={this.props.desktopImage  ?? placeHolderTeamDesktop} />
          <source media="(min-width: 720px)" srcSet={this.props.tabletImage  ?? placeHolderTeamTablet} />
          <img className="image-teaser-image" src={this.props.mobileImage ?? placeHolderTeamMobile} alt={this.props.name} />
        </picture>
        <figcaption className="image-teaser-overlay">
          <div className="image-teaser-meta">
            <span className="image-teaser-title">{this.props.name}</span>
            <div className="image-teaser-description">
              <p>{this.nl2br(this.props.description)}</p>
              <div>
                {this.props.portraitUrl ? <ExternalLink to={this.props.portraitUrl}><DownloadIcon/>Portrait</ExternalLink> : null}                
                {this.props.mail ? <a href={`mailto:${this.props.mail}`}><MailIcon /></a> : null }
              </div>              
            </div>            
          </div>
        </figcaption>
      </figure>
    )
  };
}

export class UeberUnsPage extends React.Component {
  render() {
    const team = [
      {id: 1, name: "Sabine Augstein", description: "Diplompsychologin/Logopädin\nLeitung wahrnehmung.ch\nSystemische Beraterin IEF\nSenior-Instruktorin Affolter-Modell®", portraitUrl: pdfPortraitSabineAugstein, mail: "sabine.augstein@wahrnehmung.ch", mobileImage: portraitSabineAugsteinMobile, tabletImage: portraitSabineAugsteinTablet, desktopImage: portraitSabineAugsteinDesktop },
      {id: 2, name: "Jris Bischof", description: "Logopädin", mobileImage: portraitJrisMobile, tabletImage: portraitJrisTablet, desktopImage: portraitJrisDesktop, portraitUrl: pdfPortraitJrisBischof, mail: "jris.bischof@wahrnehmung.ch" },
      {id: 3, name: "Lara Carlot", description: "Logopädin\nStellvertretung Leitung\nReferentin im Affolter-Modell®\nFachfrau UK", mobileImage: portraitLaraMobile, tabletImage: portraitLaraTablet, desktopImage: portraitLaraDesktop, portraitUrl: pdfPortraitLaraCarlot, mail: "lara.carlot@wahrnehmung.ch" },
      {id: 4, name: "Isabel Knechtel", description: "Logopädin", mobileImage: portraitSabineMobile, tabletImage: portraitSabineTablet, desktopImage: portraitSabineDesktop, portraitUrl: pdfPortraitIsabelKnechtel, mail: "isabel.knechtel@wahrnehmung.ch" },
      {id: 5, name: "Tabea Wössner", description: "Referentin im Affolter-Modell®", mobileImage: portraitTabeaMobile, tabletImage: portraitTabeaTablet, desktopImage: portraitTabeaDesktop, portraitUrl: pdfPortraitTabeaWoessner, mail: "tabea.woessner@wahrnehmung.ch" },
      {id: 6, name: "Cathrine Steiger", description: "Sekretariat", mail: "sekretariat@wahrnehmung.ch", mobileImage: portraitCatherineMobile, tabletImage: portraitCatherineTablet, desktopImage: portraitCatherineDesktop, portraitUrl: pdfPortraitCatrhineSteiger },
      {id: 7, name: "Alex Bubenhofer", description: "Stiftungsrats-Präsident\nVereinigung der Ostschweizer Kinderärzte, St. Gallen", portraitUrl: pdfPortraitAlexBubenhofer },
      {id: 8, name: "Christian Künzler", description: "Stiftungsrat-Vizepräsident\ndipl. Wirtschaftsprüfer", portraitUrl: pdfChristianKuenzler },
      {id: 9, name: "Adrian Hofer", description: "Stiftungsrat\nlic. phil. Psychologe / Logopäde", portraitUrl: pdfPortraitAdrianHofer },
      {id: 10, name: "Brigitte Pastewka", description: "Stiftungsrätin\nSonderpädagogin / Erwachsenenbildnerin", portraitUrl: pdfPortraitBrigittePastewka}
    ];
    return  [
      <Helmet key="helmet">
        <title>Über uns</title>
      </Helmet>,
      <Article title="Die gemeinnützige Stiftung wurde 1977 von Félicie Affolter, der Entwicklerin des Affolter-Modells®, gegründet." key="article">
        <Section title="Wir sind immer noch für Sie da.">
          <ColumnLeft className="contact-form">
            <p className="teaser">Nehmen Sie Kontakt auf mit uns. Schreiben Sie uns eine E-Mail, benutzen Sie unser Kontaktformular oder rufen Sie uns einfach an.</p>
            <ContactForm />
          </ColumnLeft>
          <ColumnRight>
            <Aside>
              <h3>Adresse</h3>
              <address>
                wahrnehmung.ch<br/>
                Florastrasse 14<br/>
                CH-9000&nbsp;St.&nbsp;Gallen<br/>
                <a href="tel:+41712220234">+41 (0)71 222 02 34</a><br/>
                <a href="mailto:info@wahrnehmung.ch">info@wahrnehmung.ch</a>
              </address>

              <h3>Erreichbarkeit</h3>
              <p style={{marginTop: 0}}>Montag bis Freitag<br/>
              8.30 bis 12 Uhr, 13.30 bis 17 Uhr</p>

              <h3>Lageplan</h3>
              <ExternalLink to="https://goo.gl/maps/Z1KXRnFTCE6orvhEA"><ArrowRight />Google Maps</ExternalLink>

              <h3>Anreise mit dem öffentlichen Verkehr</h3>
              <p style={{marginBottom: "7px"}}>Bus Nr. 1, 4, 7 oder 8 ab Bahnhof St.&nbsp;Gallen Richtung Stephanshorn oder Guggeien, Haltestelle Singenberg. 
              3 Minuten Fussweg bis Florastr. 14.</p>
              <ExternalLink to="https://www.sbb.ch/de/kaufen/pages/fahrplan/fahrplan.xhtml?nach=9000+St.+Gallen%2C+Singenberg"><ArrowRight />SBB Fahrplan</ExternalLink>
            </Aside>
          </ColumnRight>
        </Section>
        <Section title="Und zwar ganz persönlich." id="team">
          <div className="image-teaser-container">
            {team.map(item => <TeamCard key={item.name} {...item} />)}
          </div>
        </Section>
        <Section title="Forschungsarbeit bei der Stiftung wahrnehmung.ch." id="forschungsarbeit">
          <p className="teaser">«Forschung ist die Fortsetzung von Neugier mit anderen Mitteln.» Wir sind und bleiben neugierig. Deshalb erforschen wir in diversen Projekten die Prozesse der Aufnahme und Verarbeitung von Sinneseindrücken. Die Ergebnisse fliessen in unsere tägliche Arbeit ein.<br/><br/></p>
          <ColumnLeft>
            <p className="teaser">Projekt WESUK – Wahrnehmungsprobleme:<br/>Erfassung von Kleinkindern</p>
            <p>Ist es möglich, Wahrnehmungsprobleme bereits in den ersten Lebensjahren zu erkennen? In Zusammenarbeit mit Ostschweizer Kinderärzt:innen hat die Stiftung eine Longitudinalstudie durchgeführt. Die Ergebnisse machten es möglich, ein Screening zu entwickeln, mit dessen Hilfe ein Verdacht für vorhandene Wahrnehmungsprobleme bei Kleinkindern aufgedeckt, aber keine Diagnose gestellt werden kann.</p>
            <p>Das Screening eignet sich für pädiatrische Vorsorgeuntersuchungen und Fachpersonen, die sich mit der frühkindlichen Entwicklung beschäftigen.<br/>
            Wir bieten Kurse an, in denen Sie das Screening kennenlernen und das entsprechende Material erwerben können.</p>
          </ColumnLeft>
          <ColumnRight>
            <Details title="Einblick in das Screening">
              <Youtube embedId="vEherhNmVZc" />
            </Details>
            <Aside>
              <h3>WESUK ‒ Wahrnehmungsprobleme</h3>
              <ExternalLink to={pdfWesuk2007}><DownloadIcon/>Download PDF</ExternalLink>
            </Aside>
          </ColumnRight>
          </Section>
          <Section>
          <ColumnLeft>
            <p className="teaser">Wissenschaftliche Erhebungen über die Wirksamkeit des Affolter-Modells®</p>
            <p>Schon länger beschäftigen wir uns mit der Wirksamkeit unserer Arbeit. In unserer tagtäglichen Arbeit erhalten wir unmittelbare Rückmeldungen über unser Tun: durch das Verhalten der Kinder und deren Entwicklungsfortschritte sowie durch Feedbacks der Bezugspersonen. Nun geht es darum, die Wirksamkeit des Affolter-Modells® wissenschaftlich zu untersuchen.</p>
            <p>Diese Untersuchung machen wir zusammen mit anderen Lehrenden im Affolter-Modell®.</p>
          </ColumnLeft>
          <ColumnRight>
            <Aside>
              <h3>Einblick in ein laufendes Projekt</h3>
              <ExternalLink to={pdfGHG2019}><DownloadIcon/>Download PDF</ExternalLink>
            </Aside>
          </ColumnRight>
        </Section>
      </Article>
    ]
  };
}

export class AngebotPage extends React.Component {
  render() {
    const items = [
      {link: "/angebot/abklaerung", name: "Abklärung", page: <AbklaerungPage key="abklaerung" />, cover: coverAbklaerung },
      {link: "/angebot/therapie", name: "Therapie", page: <TherapiePage key="therapie" />, cover: coverTherapie },
      {link: "/angebot/beratung", name: "Beratung", page: <BeratungPage key="beratung" />, cover: coverBeratung },
      {link: "/angebot/kurse", name: "Kurse", page: <KursePage key="kurse" />, cover: coverKurse }
    ];

    return (
    <Article title="Alles im offenen Miteinander und Austausch mit Ihnen.">
      <ContentMenu items={items}>
        <Switch>
          {items.map(item => 
          <Route path={item.link} key={item.link}>
            <Cover key={item.cover.desktopImage} {...item.cover} />
          </Route>)
          }
        </Switch>
      </ContentMenu>
      <Switch>
        <Route exact path="/angebot">
          <Redirect to="/angebot/abklaerung" />
        </Route>
        {items.map(item => <Route path={item.link} key={item.link} >
          {item.page}
        </Route>)}
      </Switch>
    </Article>
    )
  };
}

export class AbklaerungPage extends React.Component {
  render() {
    return [
    <Helmet key="helmet">
      <title>Unser Angebot - Abklärung</title>
    </Helmet>,
    <Section title="Eine Abklärung hilft weiter." key="section">
      <ColumnLeft>
      <p className="teaser">Die Auslöser für eine Abklärung sind unterschiedlich: Entwicklungs- und Verhaltensauffälligkeiten, Schulschwierigkeiten, Probleme im Erwachsenenalltag. Oder die Folgen einer Hirnschädigung.</p>
      <h3>Was gehört zu einer Abklärung?</h3>
      <p>Die Abklärung umfasst Tests und Gespräche. Aus unseren Beobachtungen ergibt sich ein Befund und wir geben Empfehlungen für das weitere Vorgehen.<br/>
Es ist uns wichtig, dass die Angehörigen und evtl. weitere Bezugspersonen bei der Abklärung anwesend sind.</p>
      <h3>Was klären wir ab?</h3>
      <p>Wir ermitteln den Entwicklungsstand. Wir klären ab, ob eine Wahrnehmungs- und Sprachproblematik vorliegt. Und wir legen bei Bedarf den therapeutischen Ansatz fest.</p>
      </ColumnLeft>
      <ColumnRight>
        <Details title="Was kostet die Abklärung?">
          <p>Der Aufwand für eine Abklärung umfasst je nach Fragestellung ca. 5 Stunden (inklusive Bericht) à CHF 170.‒.</p>
          <p>Die Finanzierung muss im Voraus geklärt werden. Kommen die öffentlichen Kostenträger (Schulgemeinden, Erziehungsdepartemente) nicht auf, müssen die Kosten privat gedeckt werden. In finanziellen Notlagen suchen wir gemeinsam nach einer Lösung.</p>
        </Details>
        <Details title="Wer meldet wen an?">
          <p>Anmeldung von Schulkindern und Jugendlichen nehmen Sie als Eltern, als externe Bezugsperson oder als Sozialinstitution vor. Kinder im Vorschulbereich werden von Kinderärtz:innen angemeldet. Auch weitere Ärzt:innen und andere Fachpersonen (z.B. Schulpsychologie) können eine Anmeldung vornehmen. Als erwachsene Person melden Sie sich selbst an oder lassen sich anmelden.</p>
        </Details>
        <ContactCard />
      </ColumnRight>
    </Section>
    ]
  }
};

export class TherapiePage extends React.Component {
  render() {
    return [
      <Helmet key="helmet">
        <title>Unser Angebot - Therapie</title>
      </Helmet>,
    <Section title="Eine Therapie bringt Veränderung." key="section1">
      <ColumnLeft>
        <p className="teaser">Unter Therapie verstehen wir eine pädagogisch-therapeutische Behandlung für Kinder, Jugendliche und Erwachsene aller Altersstufen.</p>
        <p>Unsere therapeutische Herangehensweise beruht schwerpunktmässig auf dem Affolter-Modell®. Je nach Fragestellung können weitere Ansätze einbezogen werden.</p>
        <p>Grundsätzlich bieten wir Therapie für alle Altersgruppen an. Je nach Alter ist die Finanzierung unterschiedlich.</p>
        <p>Die Stiftung&nbsp;wahrnehmung.ch ist im Kanton St.&nbsp;Gallen eine anerkannte Stelle für «Logopädie im Vorschulalter».<br/>
        Ausserdem bestehen Leistungsvereinbarungen mit der Stadt St.&nbsp;Gallen und den Kantonen Appenzell Ausserrhoden und Innerrhoden.</p>
        <p>Die Therapie findet ambulant statt. Bei uns in St.&nbsp;Gallen. Oder bei Bedarf bei Ihnen zu Hause, in der Schule oder im Heim.<br/>
        Wir führen sowohl Einzeltherapien als auch Therapien in Kleingruppen durch.</p>
      </ColumnLeft>
      <ColumnRight>
        <Details title="Was kostet die Therapie?">
          <p>Die Kosten übernehmen Schulgemeinden, kantonale Stellen oder zuweisende Institutionen. Zum Teil kommen auch Versicherungen und private Stiftungen dafür auf.</p>
        </Details>
        <Details title="Wer meldet wen an?">
          <p>Anmeldung von Schulkindern und Jugendlichen nehmen Sie als Eltern, als externe Bezugsperson oder als Sozialinstitution vor. Kinder im Vorschulbereich werden von Kinderärtz:innen angemeldet. Auch weitere Ärzt:innen und andere Fachpersonen (z.B. Schulpsychologie) können eine Anmeldung vornehmen. Als erwachsene Person melden Sie sich selbst an oder lassen sich anmelden.</p>
        </Details>
        <ContactCard />
      </ColumnRight>
    </Section>,
    <Section title="Verbesserte Wahrnehmung dank Therapie." key="section2">
      <ColumnLeft>
        <h3>Alltägliche Probleme meistern</h3>
        <p className="teaser">In alltäglichen Situationen erfahren wir die Beziehung zwischen Körper und Umwelt und erhalten «gespürte Information». Diese erlaubt es uns, Veränderungen, die wir bewirken, besser wahrzunehmen.</p>
        <p>Ist diese Wahrnehmung beeinträchtigt, können unsere Therapeut:innen die Hände und den Körper der Patient:innen ganz gezielt führen. Dabei geht es um eine Verbesserung der Wahrnehmungsorganisation und nicht um die Verbesserung der motorischen Fertigkeiten.</p>
      </ColumnLeft>
      <ColumnRight>
        <Details title="Einblick in die Therapie">
          <p>Chantal kam 1998 in Kontakt mit wahrnehmung.ch. Heute lebt sie weitgehend selbständig, besucht aber weiterhin sporadisch die Therapie in der Stiftung&nbsp;wahrnehmung.ch.</p>
          <Youtube embedId="SmPodPBxP_Y" />
        </Details>
      </ColumnRight>
    </Section>,
    <Section key="section3">
      <ColumnLeft>
        <h3>Sprachprobleme meistern</h3>
        <p>Ein wichtiger Schritt in der frühkindlichen Entwicklung ist die Entdeckung, dass Sprache Veränderung bewirkt. Ebenso wichtig: Dass das Kind diese Veränderung auch tatsächlich wahrnehmen kann. Ist diese Wahrnehmung beeinträchtigt, besteht die Gefahr einer auffälligen Sprachentwicklung.</p>
        <p>Die Therapie setzt an diesem Zusammenhang zwischen Wirken und Sprache an. Das heisst: wahrnehmung.ch macht kein Sprachtraining. Wir setzen Situationen und Erlebnisse in Sprache um, die beim Lösen alltäglicher Aufgaben entstanden sind.</p>
      </ColumnLeft>
      <ColumnRight>
        <Details title="Wie Liam Wirkung und Sprache entdeckt.">
          <Youtube embedId="_UssyKf7tLY" />
        </Details>
        <Details title="Wie der zurückgezogene, passive Samuel zu einem Stück Selbständigkeit fand.">
          <Youtube embedId="Uz1a8xxoQS0" />
        </Details>
        <Details title="Wie Vinzenz nach einer Hirnverletzung an Erfahrungen anknüpft.">
          <Youtube embedId="zU_mS4RVwRk" />
        </Details>
        <Details title="Wie etwas Gemeinsames entsteht">
          <Youtube embedId="qv6JCdU36Gw" />
        </Details>
        <Details title="Mehr Einblicke">
        <p>Familie Hunziker<br/>
        <ExternalLink to={pdfPortraitFamilieHunziker}><DownloadIcon/>Download PDF</ExternalLink>
        </p>
        <p>Familie Rickenbach-Himmelberger<br/>
        <ExternalLink to={pdfPortraitNico}><DownloadIcon/>Download PDF</ExternalLink>
        </p>
        <p>Familie Tanner<br/>
        <ExternalLink to={pdfPortraitSamuel}><DownloadIcon/>Download PDF</ExternalLink>
        </p>
        </Details>        
      </ColumnRight>
    </Section>
    ]
  }
};

export class BeratungPage extends React.Component {
  render() {
    return [
      <Helmet key="helmet">
        <title>Unser Angebot - Beratung</title>
      </Helmet>,
    <Section title="Wir sind Ihre Ansprechpersonen." key="section1">
      <ColumnLeft>
        <p className="teaser">Wir beraten Eltern und alle, die mit Kindern, Jugendlichen und Erwachsenen arbeiten. Und dabei auf irgendeine Art mit dem Thema Wahrnehmung in Berührung kommen.</p>
        <p>Wir beraten auch Regelschulen, Sonderschulen, Alterszentren, neurologische Reha-Kliniken und Institutionen für erwachsene Menschen mit Behinderungen.</p>
      </ColumnLeft>
      <ColumnRight>
        <Details title="Was kostet die Beratung?">
          <p>Für Eltern ist die Beratung in der Therapie ihres Kindes integriert.<br/>
          Mit Fachpersonen und Institutionen werden die Kosten im Voraus geregelt, je nach Aufwand, Umfang und Fragestellung.</p>
        </Details>
        <Details title="Wer meldet wen an?">
          <p>Die Anmeldung für eine Beratung, bzw. Therapie nehmen Sie als Eltern, als externe Bezugsperson oder als Sozialinstitution vor.<br/>
          Für Fachberatungen melden in der Regel die Institutionen ihr Personal bei uns.</p>
        </Details>
        <ContactCard />
      </ColumnRight>
    </Section>,
    <Section title="Unser Beratungsangebot" key="section2">
      <ColumnLeft>
        <h3>Die Beratung für Eltern</h3>
        <p className="teaser">Wir bieten Ihnen eine umfassende Beratung zu allen Themen an, die Sie rund um die Wahrnehmungsproblematik Ihres Kindes beschäftigen.</p>
      </ColumnLeft>
      <ColumnRight>
        <Details title="Beispiele">
          <ul>
            <li>Erziehungsfragen</li>
            <li>Auswirkungen der Schwierigkeiten Ihres Kindes auf die ganze Familie oder einzelne Beziehungen (z.B. Geschwister, Elternpaar)</li>
            <li>Umgang mit der Behinderung des Kindes</li>
            <li>Umgang mit weiteren Bezugspersonen (Familienangehörige, Schule, Nachbarn)</li>
            <li>Konflikthafte Situationen in der Familie, belastende Lebensereignisse</li>
          </ul>
        </Details>
      </ColumnRight>
    </Section>,
    <Section key="section3">
      <ColumnLeft>
        <h3>Die Beratung für Fachpersonen</h3>
        <p className="teaser">Was immer Sie als Fachperson beschäftigt – fragen Sie uns. Wir behandeln gemeinsam mit Ihnen zum Beispiel folgende Fragestellungen:</p>

        <ul>
          <li>Wie lernen Kinder (Entwicklung)?</li>
          <li>Wie lernen Kinder mit Problemen in der Wahrnehmung?</li>
          <li>Wie erkenne ich eine Wahrnehmungsproblematik?</li>
          <li>Was hilft diesen Kindern?</li>
          <li>Wie gestalte ich Betreuungszeiten in Gruppen?</li>
          <li>Welche Aktivitäten sind sinnvoll und wie gestalte ich sie?</li>
          <li>Wie kann ich dabei mit «Führen» helfen?</li>
          <li>Wie gestalte ich die räumliche Wohnsituation?</li>
        </ul>
      </ColumnLeft>
      <ColumnRight>
        <Details title="Ein Beratungsbeispiel">
          <p>Heilpädagogisches Schul- und Beratungszentrum Sonnenberg in Baar.</p>
          <Youtube embedId="rvNf4omFWy8" />
        </Details>
        <Details title="Beispiel Sonnenhalde Tandem">
          <p>Wohn- und Arbeitsgruppen für Erwachsene mit Wahrnehmungsproblemen in Engelburg.</p>
          <Youtube embedId="HXHJ5cKuWgU" />
          <ExternalLink to="https://ghg-sonnenhalde-tandem.ch" ><ArrowRight/>Tandem</ExternalLink>
        </Details>
      </ColumnRight>
      
    </Section>
    ]
  }
};

interface IKursePageState {
  grundkurse?: IKursItemProps[];
  weiterfuehrendekurse?: IKursItemProps[];
  spezialkurse?: IKursItemProps[];
}

export class KursePage extends React.Component<{}, IKursePageState> {

  private dataProvider: CourseDataProvider = new CourseDataProvider();

  constructor(props: any){
    super(props);

    this.state = {
      grundkurse: undefined,
      weiterfuehrendekurse: undefined,
      spezialkurse: undefined,
    };
  }

  componentDidMount() {
    this.dataProvider.getCourseList("grundkurs").then( data => 
      {
        this.setState({
          grundkurse: data
        })
      }
    );
    this.dataProvider.getCourseList("weiterfuehrend").then( data => 
      this.setState({
        weiterfuehrendekurse: data
      })
    );
    this.dataProvider.getCourseList("spezial").then( data => 
      this.setState({
        spezialkurse: data
      })
    );
  }

  render() {
    return [
      <Helmet key="helmet">
        <title>Unser Angebot - Kurse</title>
      </Helmet>,
      <Section title="Hier kommen Sie weiter." key="section1">
        <ColumnLeft>
          <p className="teaser">Wir entwickeln individuelle Weiterbildungen, ganz nach Ihren Vorgaben.</p>
          <p>Unsere Stiftung führt für Fachpersonen und Angehörige regelmässig Kurse zum Affolter-Modell® durch. Das Angebot umfasst den Grundkurs in Modulen, weiterführende Kurse ‒ alle APW-anerkannt (Arbeitsgemeinschaft pro Wahrnehmung) ‒ und Spezialkurse.</p>
          <Link to="#grundkurse" className="btn btn-internal">Grundkurs</Link>
          <Link to="#weiterfuehrendekurse" className="btn btn-internal">Weiterführende Kurse</Link>
          <Link to="#spezialkurse" className="btn btn-internal">Spezialkurse</Link>
        </ColumnLeft>
        <ColumnRight>
          <Details title="Beispiel Fortbildung für Lehrpersonen">
            <Youtube embedId="q_DylJJR62k" />
          </Details>
          <Details title="Beispiel eines Elternkurses">
            <Youtube embedId="8jGH5NVJCIs" />
          </Details>
          <ContactCard />
        </ColumnRight>        
      </Section>,
      <Section title="Grundkurs in Modulen." className="alternate-background" id="grundkurse" key="grundkurse">
        <p className="teaser">Der Grundkurs besteht aus drei Modulen, insgesamt sechs Kurswochen. In der letzten Woche des Zertifizierungsmoduls haben Sie die Möglichkeit, eine Lehr-/Lernzielkontrolle (LLK) zu absolvieren. Die bestandene LLK ist Voraussetzung für die Erlangung einer APW-anerkannten Zusatzqualifikation als Therapeut:in im Affolter-Modell®. Detaillierte Infos zur Qualifikation gibt es bei der APW.</p>
        <ExternalLink to="https://www.apwschweiz.ch/de/apw-kurssystem/anerkennungen"><ArrowRight/>apwschweiz.ch</ExternalLink>
        {this.state.grundkurse === undefined ? <p>Kurse werden geladen...</p> : <KursCarousel kurse={this.state.grundkurse} noKurseMessage="Zur Zeit finden keine Grundkurse statt." />}
      </Section>,
      <Section title="Weiterführende Kurse." forceBreak={true} id="weiterfuehrendekurse" key="weiterfuehrendekurse">
        <p className="teaser">Der Besuch eines weiterführenden Kurses setzt den Besuch eines APW-anerkannten Grundkurses im Affolter-Modell® oder eines APW-anerkannten fünftägigen Einführungsmoduls/Einführungskurses mit regelmässiger praktischer Anwendung des Affolter-Modells® voraus.</p>
        <ExternalLink to="https://www.apwschweiz.ch"><ArrowRight/>apwschweiz.ch</ExternalLink>
        {this.state.weiterfuehrendekurse === undefined ? <p>Kurse werden geladen...</p> : <KursCarousel kurse={this.state.weiterfuehrendekurse} noKurseMessage="Zur Zeit sind keine weiterführenden Kurse geplant." />}
      </Section>,
      <Section title="Spezialkurse." forceBreak={true} id="spezialkurse" key="spezialkurse">
        {this.state.spezialkurse === undefined ? <p>Kurse werden geladen...</p> : <KursCarousel kurse={this.state.spezialkurse} noKurseMessage="Zur Zeit finden keine Spezialkurse statt." />}
      </Section>
    ]
  };
}

interface INewsItemProps {
  mobileImage: string;
  tabletImage: string;
  desktopImage: string;
  title: string;
}

export class NewsItem extends React.Component<INewsItemProps> {
  render(){
    return (
      <div className="news-item">
        <picture>
          <source media="(min-width: 1140px)" srcSet={this.props.desktopImage} />
          <source media="(min-width: 720px)" srcSet={this.props.tabletImage} />
          <img src={this.props.mobileImage} alt={this.props.title} />
        </picture>
        <div className="news-content">
          <h3>{this.props.title}</h3>
          { this.props.children }
        </div>
      </div>
    )
  };
}

interface INewsletterCardState {
  name: string;
  firstName: string;
  email: string;
  subscribed: boolean;
}

export class NewsletterCard extends React.Component<{}, INewsletterCardState> {

  constructor(props: any){
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      name: "",
      firstName: "",
      email: "",
      subscribed: false
    }
  }

  handleSubmit(event: any) {
    var data = {
      name: this.state.name,
      firstName: this.state.firstName,
      email: this.state.email
    };

    fetch('/api/newsletterAddress/subscribe', {
      method: "POST", 
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(data) 
    })
      .then(response => {
        if(response.ok){
          this.setState({
            subscribed: true
          });

          ReactGA.event({
            category: 'Newsletter',
            action: 'Subscription'
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);

        ReactGA.event("exception", 
        {
          description: 'Could not subscribe newsletter. ' + error.message,
          fatal: true
        });
      });    

    event.preventDefault();
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    } as unknown as INewsletterCardState);
  }

  render(){
    return (
      <Section title={ this.state.subscribed ? "Vielen Dank für Ihre Anmeldung.": "Sind Sie auf dem Laufenden?"} className="inside-newsletter" id="newsletter">
        <ColumnLeft>
          { this.state.subscribed ? null:<p className="teaser">Was gibt es Neues bei der Stiftung wahrnehmung.ch? Mit unserem Newsletter sind Sie immer auf dem aktuellen Stand.</p>}
          { this.state.subscribed ? <p className="teaser spacer">Wir haben Ihnen eine E-Mail geschickt. Um die Newsletter-Anmeldung zu bestätigen, klicken Sie bitte auf den entsprechenden Link.</p> : <form onSubmit={this.handleSubmit}>
            <input type="text" value={this.state.name} onChange={this.handleInputChange} name="name" placeholder="Name*" required />
            <input type="text" value={this.state.firstName} onChange={this.handleInputChange} name="firstName" placeholder="Vorname*" required />
            <input type="email" value={this.state.email} onChange={this.handleInputChange} name="email" placeholder="E-Mail*" required />
            <button type="submit" className="btn btn-internal">Abonnieren</button>
          </form> }
        </ColumnLeft>
        <ColumnRight>
        <div className="backdrop">
          <picture>
            <source media="(min-width: 1140px)" srcSet={googelDesktop} />
            <source media="(min-width: 720px)" srcSet={googelTablet} />
            <img src={googelSmall} alt="" />
          </picture>
        </div>
        </ColumnRight>
      </Section>
    )
  }
}

export class NewsletterUnsubscriptionPage extends React.Component {
  render(): React.ReactNode {
      return [
        <Helmet key="helmet">
          <title>Abmeldung Newsletter.</title>
        </Helmet>,
        <Article title='Inside wahrnehmung.ch'>
          <Section title="Ihre Abmeldung wurde erfolgreich verarbeitet." className="inside-newsletter" id="newsletter">
            <ColumnLeft>
            <p className="teaser spacer">Schade dass Sie sich nicht mehr für den Newsletter interessieren. Sie wurden erfolgreich aus dem Verteiler entfernt.</p>
            </ColumnLeft>
            <ColumnRight>
            <div className="backdrop">
              <picture>
                <source media="(min-width: 1140px)" srcSet={googelDesktop} />
                <source media="(min-width: 720px)" srcSet={googelTablet} />
                <img src={googelSmall} alt="" />
              </picture>
            </div>
            </ColumnRight>
          </Section>
        </Article>
      ]
  };
}

export class NewsletterValidationPage extends React.Component {
  render(): React.ReactNode {
      return [
        <Helmet key="helmet">
          <title>Anmeldung Newsletter.</title>
        </Helmet>,
        <Article title='Inside wahrnehmung.ch'>
          <Section title="Vielen Dank für Ihre Anmeldung." className="inside-newsletter" id="newsletter">
            <ColumnLeft>
            <p className="teaser spacer">Die Anmeldung für den Newsletter wurde erfolgreich bestätigt. Sie hören von uns, sobald der nächste Newsletter versandt wird.</p>
            </ColumnLeft>
            <ColumnRight>
            <div className="backdrop">
              <picture>
                <source media="(min-width: 1140px)" srcSet={googelDesktop} />
                <source media="(min-width: 720px)" srcSet={googelTablet} />
                <img src={googelSmall} alt="" />
              </picture>
            </div>
            </ColumnRight>
          </Section>
        </Article>
      ]
  };
}

export class NewsletterErrorPage extends React.Component {
  render(): React.ReactNode {
      return [
        <Helmet key="helmet">
          <title>Fehler bei der An-/Abmeldung Newsletter.</title>
        </Helmet>,
        <Article title='Inside wahrnehmung.ch'>
          <Section title="Da ist etwas schief gelaufen." className="inside-newsletter" id="newsletter">
            <ColumnLeft>
            <p className="teaser spacer">Da hat leider etwas nicht geklappt, das Sekretariat der Stiftung&nbsp;wahrnehmung.ch hilft gerne weiter: <a href="tel:+41712220234">+41&nbsp;(0)71&nbsp;222&nbsp;02&nbsp;34</a>, <a href="mailto:info@wahrnehmung.ch">info@wahrnehmung.ch</a></p>
            </ColumnLeft>
            <ColumnRight>
            <div className="backdrop">
              <picture>
                <source media="(min-width: 1140px)" srcSet={googelDesktop} />
                <source media="(min-width: 720px)" srcSet={googelTablet} />
                <img src={googelSmall} alt="" />
              </picture>
            </div>
            </ColumnRight>
          </Section>
        </Article>
      ]
  };
}

export class InsidePage extends React.Component {
  render(){
      const breakPoints = [
        { width: 1, itemsToShow: 1, showArrows: false },
        { width: 720 - 60, itemsToShow: 1, showArrows: false },
        { width: 1080, itemsToShow: 1, itemsToScroll: 1 }
      ];

      return [
        <Helmet key="helmet">
          <title>Inside wahrnehmung.ch</title>
        </Helmet>,
        <Article title="Inside wahrnehmung.ch" key="inside-article">
          <Section title="Aktuell">
            <CustomizedCarousel breakPoints={breakPoints}>
            <NewsItem title="2023: Wir halten Rückschau – und blicken nach vorne." mobileImage={newsJahresberichtMobile} tabletImage={newsJahresberichtTablet} desktopImage={newsJahresberichtDesktop}>
                <p>Lesen Sie im Jahresbericht 2023, was im vergangenen Jahr bei uns gelaufen ist und was uns in der
Zukunft beschäftigen wird.</p>
<ul>
  <li>Bericht des Präsidenten: Rückblick auf ein erfolgreiches Jahr – Ausblick auf Veränderungen.</li>
  <li>Bericht der Leiterin: Ein gutes, intensives Jahr – erfolgreich in vielen Bereichen.</li>
  <li>Wahrnehmungsstörungen. Erkennen, verstehen und unterstützen. Ein Fachartikel.</li>
</ul> 
<p>Details zu diesen Themen und weitere Einblicke in das Stiftungsjahr 2023 im:</p>
<ExternalLink to={pdfJahresbericht}><DownloadIcon/>Jahresbericht 2023</ExternalLink>
              </NewsItem>
              <NewsItem title="Die Grunds&auml;tze des Affolter-Modells&reg;" mobileImage={newsGrundsaetzeMobile} tabletImage={newsGrundsaetzeTablet} desktopImage={newsGrundsaetzeDesktop}>
                <p>Der Artikel von Adrian Hofer und Tabea W&ouml;ssner gibt einen umfassenden &Uuml;berblick &uuml;ber die
Forschungsarbeit von Dr. F&eacute;licie Affolter, in der sie mit ihren Mitarbeiterinnen und Mitarbeitern ein
Entwicklungsmodell erarbeitet. Dieses Modell erkl&auml;rt sowohl unauff&auml;llige als auch auff&auml;llige
Entwicklungsverl&auml;ufe.</p>
<ExternalLink to={pdfGrundsaetzeAffolter}><DownloadIcon/>Fachartikel</ExternalLink>
              </NewsItem>
              <NewsItem title="Lesenswerte Neuerscheinung" mobileImage={newsLauraMobile} tabletImage={newsLauraTablet} desktopImage={newsLauraDesktop}>
                <p>Die Psychologin Laura Sergi ist bei wahrnehmung.ch keine Unbekannte. Sie hat im Rahmen des Psychologiestudiums in der Stiftung erst ein Praktikum absolviert und anschliessend als Psychologin gearbeitet. Gleichzeitig hat sie ein Buch mit dem Titel «Die Bedeutung des Spürens beim Zusammenspiel von Wahrnehmung, Handlung und Kognition» geschrieben, welches im Frühsommer 2021 veröffentlicht wurde. Wir sind uns einig: Ein Muss für alle, die sich mit dem Thema Wahrnehmung beschäftigen.</p>

                <p>Laura Sergi: Die Bedeutung des Spürens beim Zusammenspiel von Wahrnehmung, Handlung und Kognition<br/>
                APW, St. Gallen, 2021</p>
              </NewsItem>
            </CustomizedCarousel>
          </Section>
          <NewsletterCard />
          <Section title="Es geht nicht ohne Sie." className="inside-spenden" id="spenden">
            <ColumnLeft>
              <p className="teaser">Abklärungen und Therapien für Menschen mit Wahrnehmungsproblemen. Kurse und Beratungen für Angehörige, Fachpersonen und Institutionen.</p>
              <p>Das sind unsere Aufgaben, die wir mit viel Engagement und Freude angehen.<br/>
              Und für die wir als Nonprofitorganisation Ihre finanzielle Unterstützung brauchen, da die öffentliche Hand unsere Aufwände nicht zu 100% übernimmt.<br/>
              Für Ihre Unterstützung gibt es zwei Möglichkeiten:</p>
              <h3>Entweder eine Spende ohne bestimmten Zweck.</h3>
              <p>Sie unterstützen damit die Stiftung&nbsp;wahrnehmung.ch als Ganzes und wir setzen Ihre Spende da ein, wo sie am dringendsten nötig ist.</p>
              <h3>Oder eine zweckgebundene Spende.</h3>
              <p>Sie helfen uns, eine Anschaffung oder Investition zu tätigen. Gerne sagen wir Ihnen, wofür wir Ihre Spende konkret einsetzen könnten.</p>
              <p className="teaser">Vielen Dank für Ihre Unterstützung!</p>
            </ColumnLeft>
            <ColumnRight>
              <Details title="Unsere Bankverbindung">
                <p>St. Galler Kantonalbank, CH-9001 St. Gallen</p>
                <p>IBAN: CH75 0078 1011 0345 4580 6 <br/> SWIFT/BIC: KBSGCH22 – BLZ: 00781</p>
              </Details>
              <ContactCard />
            </ColumnRight>
          </Section>
          <Section title="Vertiefte Auseinandersetzung." className="inside-impressum">
            <Details title="Literatur" id="literatur">
              <p className="teaser">Was Sie hier auf dieser Webseite zu den Themen Wahrnehmung, Affolter-Modell® und Therapiemöglichkeiten lesen, gibt Ihnen einen ersten Überblick.<br/>
              Wenn Sie sich intensiver damit beschäftigen möchten, empfehlen wir Ihnen folgende Bücher und Publikationen:</p>
              <ul>
                <li>Affolter Félicie: Wahrnehmung, Wirklichkeit und Sprache<br/>
                Neckar-Verlag, Villingen-Schwenningen, 1987</li>
                <li>Affolter Félicie &amp; Bischofberger Walter: Nichtsprachliches Lösen von Problemen in Alltagssituationen bei normalen Kindern und Kindern mit Sprachstörungen<br/>
                Neckar-Verlag, Villingen-Schwenningen, 2007</li>
                <li>Affolter Félicie &amp; Bischofberger Walter: Lernen im Alltagsgeschehen<br/>
                In: Andreas Fröhlich (Hrsg.): Pädagogik bei schwerster Behinderung (Handbuch der Sonderpädagogik, Bd. 12), S.&nbsp;241‒247. Ed. Marhold im Wiss. Verlag Spiess, Berlin, 1991</li>
                <li>Affolter Félicie et al.: Erfassung der Wirksamkeit gespürter Interaktionstherapie bei der Behandlung von Patienten mit erworbener Hirnschädigung<br/>
                Neurologie &amp; Rehabilitation 15 (1), S.&nbsp;12‒17, Hippocampus-Verlag, 2009</li>
                <li>Affolter Félicie, Bischofberger Walter, Hofer Adrian &amp; Neuweiler Matthias:<br/>
                Wurzelwerk, wissenschaftliche Beiträge aus Forschung, Lehre und Praxis zur Rehabilitation von Menschen mit Behinderungen WB LIV<br/>
                Neckar-Verlag, Villingen-Schwenningen, 2010</li>
                <li>Augstein Sabine: Nonverbale Problemlösefähigkeiten bei 2- bis 3-jährigen sprachentwicklungsverzögerten und entwicklungsunauffälligen Kindern<br/>
                Diplomarbeit, Universität Konstanz, 2007</li>
                <li>Augstein Sabine &amp; Pastewka Brigitte: Praxis des Affolter-Modells® bei Kindern mit Wahrnehmungsstörungen<br/>
                Praxis der Kinder Reha II, Verlag Modernes Lernen, Dortmund, 2008
                </li>
                <li>Hofer Adrian: Das Affolter-Modell® ‒ Entwicklungsmodell und gespürte Interaktionstherapie, Neckar-Verlag, Villingen-Schwenningen, 2023</li>
                <li>Sell-Krude Sabina: Affolter-Modell®<br/>
                In: H. Becker &amp; U. Steding-Albrecht (Hrsg.): Ergotherapie im Arbeitsfeld Pädiatrie, Thieme-Verlag, Stuttgart, 2006</li>
                <li>Sergi Laura: Die Bedeutung des Spürens beim Zusammenspiel von Wahrnehmung, Handlung und Kognition<br/>
                APW, St.&nbsp;Gallen, 2021</li>
              </ul>
            </Details>
            <Details title="Impressum" id="impressum" >
              <p className="teaser">Für den Inhalt dieser Webseite verantwortlich:</p>
              <address>
                  Stiftung&nbsp;wahrnehmung.ch,
                  Florastrasse 14,
                  CH-9000 St.&nbsp;Gallen,
                  <a href="tel:+41712220234">+41 (0)71 222 02 34</a>,
                  <a href="mailto:info@wahrnehmung.ch">info@wahrnehmung.ch</a>;
              </address>

              <p>Konzept, Text, Design, Grafik: Werbeagentur Seiler-Spiess, www.seiler-spiess.ch;<br/>
              Programmierung: Raphael Müller;<br/>
              Fotografie: Ueli Steingruber</p>

              <h2>AGB</h2>
              <h3>Rechtliche Informationen</h3>
              <p>Sämtliche Informationen, Dokumente oder andere Angaben auf den Webseiten von wahrnehmung.ch unterliegen den nachfolgenden Bestimmungen. Bitte lesen Sie diese sorgfältig durch. Mit dem Benutzen und/oder Abrufen von Informationen und Dokumenten von den Webseiten von wahrnehmung.ch geben Sie ausdrücklich Ihre Zustimmung zu folgenden Bedingungen:</p>

              <h3>Kein Angebot / Gewährleistungsausschluss</h3>
              <p>Zweck der Webseiten ist die Informationsvermittlung über unsere Dienstleistungen. Sie stellen kein Angebot im rechtlichen Sinn dar. Die Webseiten wurden nach bestem Wissen erarbeitet, doch wird keine Garantie dafür übernommen, dass die vermittelten Informationen, Dokumente oder andere Angaben fehlerfrei und vollständig sind.</p>

              <h3>Haftungsbeschränkungen</h3>
              <p>wahrnehmung.ch und ihre Betreiber:innen haften in keinem Fall für allfällige direkte oder indirekte Schäden und Folgeschäden, die aufgrund des Gebrauchs von Informationen und Material aus den Webseiten oder durch den Zugriff über Links auf andere Webseiten entstehen. Wir übernehmen keine Garantie, dass Informationen, Dokumente oder andere Angaben, welche über eine ihrer Webseiten zugänglich sind, frei von Viren oder anderen schädlichen Komponenten sind.</p>
              <h3>Verknüpfungen mit anderen Webseiten (Links)</h3>
              <p>Es ist möglich, mit Links über diese Webseiten zu anderen Internet-Seiten zu gelangen, die nicht von wahrnehmung.ch unterhalten werden. Solche externen Internet-Adressen enthalten Informationen, die von Organisationen und natürlichen oder juristischen Personen, die von wahrnehmung.ch rechtlich unabhängig sind, geschaffen, veröffentlicht, unterhalten oder anderweitig zur Verfügung gestellt werden. wahrnehmung.ch ist weder für den Inhalt dieser Seiten verantwortlich noch billigen, unterstützen oder bestätigen wir Informationen, die auf externen Seiten oder in darin aufgeführten gelinkten Adressen enthalten sind.</p>
              <h3>Datenschutz</h3>
              <p>Sämtliche Personendaten werden gemäss den geltenden Datenschutzbestimmungen bearbeitet. Wir können jedoch keine Verantwortung und/oder Haftung für den Datenschutz übernehmen. Erhalten wir von Ihnen Daten via Internet, werden diese nicht an Dritte weitergegeben und nur für die gewünschten Zwecke genutzt.</p>
              <h3>Google</h3>
              <p>«Diese Website verwendet Google Analytics, einen von Google, Inc. (‹Google›) zur Verfügung gestellten Webanalyse-Service. Google Analytics verwendet ‹Cookies›, d.h. Textdateien, die auf Ihrem Computer abgelegt werden und die Website dabei unterstützen zu analysieren, wie Besucher die Site nutzen. Die von dem Cookie über Ihre Nutzung der Website generierten Informationen (einschliesslich Ihrer IP-Adresse) werden an Google weitergeleitet und von Google auf sich in den USA befindlichen Servern gespeichert. Google nutzt die Informationen dazu, Ihre Nutzung der Website auszuwerten, Berichte über die Website-Aktivität für Website-Betreiber zu erstellen und andere Dienstleistungen in Verbindung mit der Website-Aktivität und der Internetnutzung anzubieten. Google kann diese Informationen auch an Dritte bekanntgeben, sofern dies vom Gesetz geboten ist oder wenn diese Dritten die Informationen im Auftrag von Google, Inc. bearbeiten. Google bringt Ihre IP-Adresse nicht mit anderen, sich im Besitz von Google, Inc. befindlichen Daten in Verbindung. Durch Auswahl der entsprechenden Browser-Einstellungen können Sie die Verwendung von Cookies ablehnen. Bitte beachten Sie jedoch hierbei, dass Sie dann eventuell nicht alle Funktionen der Website nutzen können. Indem Sie diese Website benutzen, akzeptieren Sie, dass Google Daten über Sie in der oben dargelegten Weise und für die oben erwähnten Zwecke bearbeitet.»</p>
              <h3>Gerichtsstand</h3>
              <p>Für alle Streitfälle, unabhängig der Nationalität der/die Teilnehmer:in und wo sich der Schadensort befindet, sind ausschliesslich Schweizer Gerichte zuständig. Gerichtsstand ist St. Gallen, Schweiz.</p>
            </Details>
          </Section>
          
        </Article>
      ]
  };
}